import {
  AccessTime,
  AccountCircle,
  Apartment,
  AssistantPhoto,
  ExpandMore,
  FiberManualRecord,
  GolfCourse,
  Group,
  LocalDining,
  MapOutlined,
  PinDrop,
  Public,
  SportsGolf,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableHead,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { groupBy } from "lodash";
import moment from "moment";
import { Close } from "@mui/icons-material";
import TemplateHeader from "../../../../components/template/Header";
import TemplateFooter from "../../../../components/template/Footer";
import ListImage from "../../../../components/PreviewContent/ListImage";
import productService from "../../../../services/productPostService";
import {
  ContentDetail,
  GolfCourseItem,
} from "../../../../interfaces/golfCourse";

interface Props {
  detailData: GolfCourseItem;
  open: boolean;
  setOpen: (data: boolean) => void;
  defaultLanguage: string;
}
const PreviewGolfCourse = ({
  open,
  setOpen,
  detailData,
  defaultLanguage,
}: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [contentGolf, setContentGolf] = React.useState<ContentDetail | null>(
    null
  );
  const fetchDetailData = async (detailData: GolfCourseItem) => {
    setLoading(true);
    const response = await productService.getDetailGolfCourse(
      detailData.information.articleCode,
      defaultLanguage
    );

    if (response?.status === 200) {
      setContentGolf(response.data.content);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (detailData) {
      fetchDetailData(detailData);
    }
  }, [detailData]);

  console.log(contentGolf);

  return (
    <Drawer
      disableEnforceFocus
      className={"drawer-common"}
      anchor="right"
      open={open}
    >
      <DialogTitle className="d-flex justify-between align-center text-white bg-tkg-blue">
        {`Preview of ${detailData.information.title}`}
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close className="text-white" />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          p: "30px 24px",
        }}
      >
        <Paper className={`bg-transparent box-unset rounded-12 h-full p-16`}>
          <>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box className="wrapper-itinerary-sendmail mb-20 mt-30">
                  <Container maxWidth="xl">
                    <>
                      {detailData !== undefined && (
                        <>
                          <Box sx={{ width: "100%" }}>
                            {contentGolf?.name && (
                              <Grid container>
                                <Typography fontSize={"32px"} fontWeight={600}>
                                  {contentGolf?.name}
                                </Typography>
                              </Grid>
                            )}
                            {detailData.information?.location && (
                              <Stack
                                direction={"row"}
                                spacing={1}
                                mb={3}
                                alignItems={"center"}
                              >
                                <PinDrop />
                                <Typography
                                  fontWeight={500}
                                  fontSize={"20px"}
                                  fontStyle={"italic"}
                                  className="text-capitalize"
                                >
                                  {JSON.parse(
                                    detailData.information?.location ?? "[]"
                                  )
                                    .join(" - ")
                                    .toLowerCase()}{" "}
                                  - {detailData.information.country}
                                </Typography>
                              </Stack>
                            )}
                            <Grid container spacing={5}>
                              <Grid item xs={12}>
                                <ListImage
                                  itineraries={[
                                    {
                                      images: JSON.stringify([
                                        String(
                                          detailData.information?.coverImage
                                        ),
                                      ]),
                                    },
                                  ]}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Paper
                                  sx={{
                                    pt: 3,
                                    px: 3,
                                    boxShadow:
                                      "0px 1px 4px 1px rgb(191 210 213 / 50%)",
                                    borderRadius: 3,
                                  }}
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems={"center"}
                                  >
                                    <Grid item xs={12} mb={3}>
                                      <Typography
                                        variant={"h6"}
                                        sx={{
                                          fontWeight: 600,
                                          mb: 2,
                                          background: "#174d75",
                                          px: 3,
                                          py: 0.5,
                                          display: "inline-block",
                                          borderRadius: 4,
                                          color: "#fff",
                                        }}
                                      >
                                        {`Golf Course Snapshot`}
                                      </Typography>
                                      <Box>
                                        <Grid container spacing={0}>
                                          {detailData.information
                                            .championship && (
                                            <Grid item xs={12} md={2}>
                                              <ListItem>
                                                <ListItemAvatar>
                                                  <Avatar
                                                    sx={{
                                                      background: "#174d75",
                                                    }}
                                                  >
                                                    <AssistantPhoto />
                                                  </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                  primary="Championship"
                                                  secondary={
                                                    detailData.information
                                                      .championship
                                                  }
                                                />
                                              </ListItem>
                                            </Grid>
                                          )}
                                          {detailData.information.designer && (
                                            <Grid item xs={12} md={2}>
                                              <ListItem>
                                                <ListItemAvatar>
                                                  <Avatar
                                                    sx={{
                                                      background: "#174d75",
                                                    }}
                                                  >
                                                    <AccountCircle />
                                                  </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                  primary="Designer"
                                                  secondary={
                                                    detailData.information
                                                      .designer
                                                  }
                                                />
                                              </ListItem>
                                            </Grid>
                                          )}
                                          {detailData.information.holes && (
                                            <Grid item xs={12} md={2}>
                                              <ListItem>
                                                <ListItemAvatar>
                                                  <Avatar
                                                    sx={{
                                                      background: "#174d75",
                                                    }}
                                                  >
                                                    <SportsGolf />
                                                  </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                  primary="Holes"
                                                  secondary={
                                                    detailData.information.holes
                                                  }
                                                />
                                              </ListItem>
                                            </Grid>
                                          )}
                                          {detailData.information.par && (
                                            <Grid item xs={12} md={2}>
                                              <ListItem>
                                                <ListItemAvatar>
                                                  <Avatar
                                                    sx={{
                                                      background: "#174d75",
                                                    }}
                                                  >
                                                    <GolfCourse />
                                                  </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                  primary="Par"
                                                  secondary={
                                                    detailData.information.par
                                                  }
                                                />
                                              </ListItem>
                                            </Grid>
                                          )}
                                          {detailData.information.metres && (
                                            <Grid item xs={12} md={2}>
                                              <ListItem>
                                                <ListItemAvatar>
                                                  <Avatar
                                                    sx={{
                                                      background: "#174d75",
                                                    }}
                                                  >
                                                    <MapOutlined />
                                                  </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                  primary="Yard"
                                                  secondary={
                                                    detailData.information
                                                      .metres
                                                  }
                                                />
                                              </ListItem>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </Grid>
                            </Grid>
                          </Box>

                          {contentGolf &&
                            contentGolf.itineraries?.length > 0 && (
                              <Box mt={5}>
                                <Grid container spacing={2}>
                                  {contentGolf.itineraries.map(
                                    (content, index) => {
                                      return (
                                        <Grid item xs={12} key={index}>
                                          <Paper
                                            sx={{
                                              p: 3,
                                              boxShadow:
                                                "0px 1px 4px 1px rgb(191 210 213 / 50%)",
                                              borderRadius: 3,
                                              mt: 2,
                                            }}
                                          >
                                            {" "}
                                            <Grid
                                              container
                                              spacing={4}
                                              direction={
                                                index % 2 === 0
                                                  ? "row"
                                                  : "row-reverse"
                                              }
                                            >
                                              <Grid
                                                item
                                                xs={12}
                                                md={
                                                  content.isIncludesImage
                                                    ? 7
                                                    : 12
                                                }
                                              >
                                                <Box
                                                  className="included-view"
                                                  dangerouslySetInnerHTML={{
                                                    __html: content.content,
                                                  }}
                                                />
                                              </Grid>
                                              {content.isIncludesImage && (
                                                <Grid item xs={12} md={5}>
                                                  <img
                                                    src={
                                                      (
                                                        content.includesImage ??
                                                        ""
                                                      ).includes("https")
                                                        ? String(
                                                            content.includesImage
                                                          )
                                                        : `${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${content.includesImage}`
                                                    }
                                                    alt="img"
                                                  />
                                                </Grid>
                                              )}
                                            </Grid>
                                          </Paper>
                                        </Grid>
                                      );
                                    }
                                  )}
                                </Grid>
                              </Box>
                            )}
                        </>
                      )}
                    </>
                  </Container>
                </Box>
              </>
            )}
          </>
        </Paper>
      </Box>
    </Drawer>
  );
};

export default PreviewGolfCourse;
